import {
  type CommonMedia,
  type DtoBlock,
  type DtoGame,
  type DtoGamePack,
  type DtoGamePackPromotionalAssets,
  type DtoJoyCapture,
  type DtoOrganizer,
  type DtoPublicOrganization,
  type DtoTag,
  type DtoUser,
  type ModelsMediaData,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import {
  type Organizer,
  type PublicOrganization,
  type Tag,
  type User,
} from '../types';
import {
  type Game,
  type GamePack,
  type GamePackPromotionalAssets,
} from '../types/game';
import { type JoyCapture } from '../types/joyCapture';

export function fromDTOBlock(blockDto: DtoBlock): Block;
export function fromDTOBlock(
  blockDto: DtoBlock | null | undefined
): Block | null;
export function fromDTOBlock(
  blockDto: DtoBlock | null | undefined
): Block | null {
  if (!blockDto) return null;
  return blockDto as unknown as Block;
}
export function fromDTOBlocks(
  blocksDto: DtoBlock[] | null | undefined
): Block[] | null {
  if (!blocksDto || !Array.isArray(blocksDto)) return null;
  return blocksDto as unknown as Block[];
}

export function toDTOBlock(block: Block): DtoBlock {
  return block as unknown as DtoBlock;
}

export function fromDTOGamePack(gamePackDto: DtoGamePack): GamePack;
export function fromDTOGamePack(
  gamePackDto: DtoGamePack | null | undefined
): GamePack | null;
export function fromDTOGamePack(
  gamePackDto: DtoGamePack | null | undefined
): GamePack | null {
  if (!gamePackDto) return null;
  return gamePackDto as unknown as GamePack;
}

export function fromDTOGamePacks(gamepacksDto: DtoGamePack[]): GamePack[];
export function fromDTOGamePacks(
  gamePacksDto: DtoGamePack[] | null | undefined
): GamePack[] | null {
  if (!gamePacksDto || !Array.isArray(gamePacksDto)) return null;
  return gamePacksDto as unknown as GamePack[];
}

export function toMediaDTO(media: Media): CommonMedia;
export function toMediaDTO(
  media: Media | null | undefined
): CommonMedia | undefined {
  if (!media || !media.id) return undefined;
  return media as unknown as CommonMedia;
}

export function fromMediaDTO(media: CommonMedia | Media): Media;
export function fromMediaDTO(
  media: Media | CommonMedia | undefined | null
): Media | null;
export function fromMediaDTO(
  media: Media | CommonMedia | undefined | null
): Media | null {
  if (!media || !media.id) return null;
  return media as unknown as Media;
}

export function toMediaDataDTO(
  mediaData: MediaData | null | undefined
): ModelsMediaData | undefined {
  if (!mediaData || !mediaData.id) return undefined;
  return mediaData as unknown as ModelsMediaData;
}

export function fromMediaDataDTO(
  mediaData: ModelsMediaData | null | undefined
): MediaData | null {
  if (!mediaData || !mediaData.id) return null;
  return mediaData as unknown as MediaData;
}

export function toDTOEnum<T>(legacyEnum: unknown, dtoEnum: T) {
  if (
    legacyEnum === undefined ||
    legacyEnum === null ||
    dtoEnum === null ||
    !dtoEnum ||
    !Object.values(dtoEnum).includes(legacyEnum)
  ) {
    throw new Error(
      `Incompatible enums: ${legacyEnum} ${JSON.stringify(dtoEnum)}`
    );
  }

  return legacyEnum as unknown as T[keyof T];
}

// NOTE: these duplicative overload definitions are necessary so that if you do:
// `toGamePackPromotionalAssetsDTO(definitelyNotNull)` you get back a
// non-nullish. Otherwise the type is transformed into null|undefined.

export function toGamePackPromotionalAssetsDTO(
  gamePackPromotionalAssets: GamePackPromotionalAssets
): DtoGamePackPromotionalAssets;
export function toGamePackPromotionalAssetsDTO(
  gamePackPromotionalAssets: GamePackPromotionalAssets | null | undefined
): DtoGamePackPromotionalAssets | undefined;
export function toGamePackPromotionalAssetsDTO(
  gamePackPromotionalAssets: GamePackPromotionalAssets | null | undefined
): DtoGamePackPromotionalAssets | undefined {
  if (!gamePackPromotionalAssets) return undefined;

  const dto: DtoGamePackPromotionalAssets = {
    ...gamePackPromotionalAssets,
    cover: {
      mediaData: toMediaDataDTO(gamePackPromotionalAssets.cover?.mediaData),
    },
  };

  return dto;
}

export function fromDTOUser(userDto: DtoUser): User;
export function fromDTOUser(userDto: DtoUser | null | undefined): User | null;
export function fromDTOUser(userDto: DtoUser | null | undefined): User | null {
  if (!userDto) return null;
  return userDto as unknown as User;
}

export function fromDTOOrganizer(organizerDto: DtoOrganizer): Organizer;
export function fromDTOOrganizer(
  organizerDto: DtoOrganizer | null | undefined
): Organizer | null;
export function fromDTOOrganizer(
  organizerDto: DtoOrganizer | null | undefined
): Organizer | null {
  if (!organizerDto) return null;
  return organizerDto as unknown as Organizer;
}

export function fromDtoJoyCapture(jc: DtoJoyCapture): JoyCapture {
  return jc as unknown as JoyCapture;
}

export function fromDtoTags(tags: DtoTag[]): Tag[] {
  return tags as unknown as Tag[];
}

export function fromDtoTag(tagDto: DtoTag): Tag {
  return tagDto as unknown as Tag;
}

export function toDtoTag(tag: Tag): DtoTag {
  return tag as unknown as DtoTag;
}

export function fromDTOGame(gameDto: DtoGame): Game;
export function fromDTOGame(gameDto: DtoGame | null | undefined): Game | null;
export function fromDTOGame(gameDto: DtoGame | null | undefined): Game | null {
  if (!gameDto) return null;
  return gameDto as unknown as Game;
}

export function fromDTOGames(gamesDto: DtoGame[]): Game[];
export function fromDTOGames(
  gamesDto: DtoGame[] | null | undefined
): Game[] | null {
  if (!gamesDto || !Array.isArray(gamesDto)) return null;
  return gamesDto as unknown as Game[];
}

export function fromDTOPublicOrganization(
  orgDto: DtoPublicOrganization
): PublicOrganization | null {
  if (!orgDto) return null;
  return orgDto as unknown as PublicOrganization;
}
